import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  
  private isFormDirtySubject = new BehaviorSubject<boolean>(false);
  
  isFormDirty$ = this.isFormDirtySubject.asObservable();

  private fileIsSelectedSubject = new BehaviorSubject<boolean>(false);

  fileIsSelected$ = this.fileIsSelectedSubject.asObservable();

  setFormDirty(isDirty: boolean) {
    this.isFormDirtySubject.next(isDirty);
  }

  fileSelected(isDirty: boolean)
  {
    this.fileIsSelectedSubject.next(isDirty);
  }

}
