import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { IUser } from 'src/app/features/manage-users/user.interface';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CourseManagementService } from '../../course-management.service';

@Component({
  selector: 'app-view-course-delivery',
  templateUrl: './view-course-delivery.component.html',
  styleUrls: ['./view-course-delivery.component.scss']

})
export class ViewCourseDeliveryComponent implements OnInit {
  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  collection = '/courseDeliveries';
  pageName: any;
  CLid: any;
  isActive;
  collectionData = "courseDeliveries";
  deleteReasonsRefData: any;
  licencesDetail;
  userType = 2

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly courseManagementService : CourseManagementService,
    private readonly sessionService: SessionsService,
    private readonly dynamicTableService: DynamicTableService,
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
  ) {
    this.courseManagementService.getBookingRefDataForReedCtp().subscribe();
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.titleInformation = [{
      'deliveryDateTime': 'Delivery days and times'
    }];
    this.schemaUrl = 'api::course-delivery.course-delivery';
    this.apiUrl = '/course-deliveries';
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.CLid = params.CLid;
      this.isActive=params.hideEdit;
    });
  }
  ngOnInit(): void {
  }


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }

  routePath(){
      this.router.navigate(['/courses-events/courseDeliveries/edit'],{queryParams:{id: this.identifier}});
  }

  formatCollectionName(data: string): string {
    let formattedName = data.endsWith('s') ? data.slice(0, -1) : data;
    formattedName = formattedName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase(); 
    if (formattedName.endsWith('deliverie')) {
      formattedName = formattedName.replace('deliverie', 'delivery');
    }
    return formattedName;
  }

}
