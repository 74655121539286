<mat-card id="Attendance">
    <mat-card-header class="flex-end flex-end1">
        <h1 class="page-title">{{courseName}}</h1>
            <button  class="right" id="exit_service_button" mat-stroked-button (click)="navigateToRoute()">Exit</button>
    </mat-card-header>
    <mat-card-content class="pl-0">
        <mat-table id="content_modules_table" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="serviceLeaverName">
                <mat-header-cell *matHeaderCellDef id="fullName_button" style="font-weight: bold;">Service leaver name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">{{ element.serviceUserName || "N/A" }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="attended">
                <mat-header-cell *matHeaderCellDef id="serviceNumber_button" style="font-weight: bold;">Attended?
                </mat-header-cell>
                <mat-cell *matCellDef="let element ; let i = index" class="m-20">
                    <mat-form-field appearance="fill">
                        <mat-label>Attended</mat-label>
                        <mat-select [(ngModel)]="element.attendedStatus" (selectionChange)="markAsModified(element)"
                            (selectionChange)="AttendanceChange($event,i)">
                            <mat-option *ngFor="let option of attendedStatus" [value]="option.id">{{ option.description
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ReasonForNotAttended">
                <mat-header-cell *matHeaderCellDef id="createdDate_button" style="font-weight: bold;">
                  Reason for not attended
                </mat-header-cell>
                <mat-cell *matCellDef="let element ; let i = index" class="m-20">
                  <mat-form-field appearance="fill" *ngIf="!element.ReasonVisibal">
                    <mat-label>Reason</mat-label>
                    <mat-select #resonInput="ngModel" [(ngModel)]="element.notAttended" (selectionChange)="markAsModified(element)" (selectionChange)="descriptionChange($event,i)" [required]="Visible(element)">
                      <mat-option *ngFor="let option of reasonNotAttended" [value]="option.id">
                        {{ option.description }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="resonInput.errors?.required" class="err-mr-top" >
                        Reason is required
                    </mat-error>
                  </mat-form-field>
                  <span *ngIf="element.ReasonVisibal">N/A</span>
                </mat-cell>
              </ng-container>
            <ng-container matColumnDef="Passes/Failed" *ngIf="!passedFailedVisibal">
                <mat-header-cell *matHeaderCellDef id="bookingReference_button" 
                    style="font-weight: bold;">Passed / Failed</mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    <mat-form-field appearance="fill" *ngIf="!element.passedFailedVisibal">
                        <mat-label>Passed / Failed</mat-label>
                        <mat-select [(ngModel)]="element.passedFailed" (selectionChange)="markAsModified(element)">
                            <mat-option *ngFor="let option of passedFailed" [value]="option.id">{{ option.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="element.passedFailedVisibal">N/A</span>
                </mat-cell>
            </ng-container>
           
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="showBrowsebutton">
          <form [formGroup]="fileFormGroup">
              <div class="uploadImg showUploadImg">
                  <div class="file-heading">Attendance file</div>
                  <span *ngIf="!logoName">
                    <div class="div-width">
                        <mat-hint class="custom-hint"> Maximum file size is 16 MB. Allowed file types are
                            pdf, ppt, pps, pptx, xls, rtf, txt, doc, docx, pub, jpeg, png</mat-hint>
                    </div>
                      <div class="browse-btn">
                          <button class="curser" type="button" id="browse_button" (click)="file.click()"
                              mat-raised-button color="primary" style="margin-top: 9px;">
                              <label>Browse</label>
                          </button>
                          <input aria-label="Browse" type="file" id="image_uploads" (change)="selectFile($event)"
                              formControlName="file" #file hidden>
                          <p class="close_btn word-nowrap" *ngIf="!logoName">{{fileName}}</p>
                          <mat-icon class="mr20 close " [matTooltip]="'Delete file'" [matTooltipShowDelay]="100" (click)="removeFile(file)"
                                *ngIf="fileName">cancel</mat-icon>
                          <mat-error *ngIf="fileCtrl.hasError('fileType')">Not a valid file type. Please update your
                              selection</mat-error>
                          <mat-error *ngIf="fileCtrl.hasError('fileSize')">This file is too large to upload. The
                              maximum
                              supported file size is 16MB</mat-error>
                          <mat-error *ngIf="fileCtrl.hasError('filelength')">File name has too many characters.
                          </mat-error>
                      </div>
                  </span>
              </div>
          </form>
          <div class="preview flex ">
              <p class="close_btn word-nowrap1" *ngIf="logoName">{{logoName}}</p>
              <div>
                <mat-icon class="mr20 close btn-clg-dl" [matTooltip]="'Delete file'" [matTooltipShowDelay]="100"
                (click)="cancelUpload()" *ngIf="logoName">cancel</mat-icon>
            <mat-icon [matTooltip]="'Download'" [matTooltipShowDelay]="100" class="download-icon mr20 btn-clg-dl"
                (click)="downloadFile()" *ngIf="logoName">get_app</mat-icon>
              </div>
          </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="py-24 padding-start">
        <button mat-raised-button color="primary"  [ngClass]="{'mrTop': !logoName}"  (click)="onSave()" [disabled]="!saveEnabled || disabledSaveButton ||!addButtonDisabled() || eventDelivryBookingData.length===0" >Save</button>
    </mat-card-actions>
</mat-card>