import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatInputModule } from "@angular/material/input";
import { QuillModule } from "ngx-quill";
import { MaterialModule } from "../../framework/material/material.module";
import { CourseManagementNavigation } from "./course-management-nav";
import { CourseManagementRoutingModule } from "./course-management-routing.module";
import { CourseManagementComponent } from "./course-management.component";
import { DynamicTableModule } from "../../framework/components/dynamic-table/dynamic-table.module";
import { CourseProviderComponent } from "./course-provider/course-provider.component";
import { AddEditCourseProviderComponent } from './course-provider/add-edit-course-provider/add-edit-course-provider.component';
import { DynamicFormsModule } from "../dynamic-forms/dynamic-forms.module";
import { CourseLocationComponent } from "./course-location/course-location.component";
import { AddCourseLocationComponent } from "./course-location/add-course-location/add-course-location.component";
import { ViewCourseLocaionComponent } from "./course-location/view-course-locaion/view-course-locaion.component";
import { ConfigOption, FormlyModule } from "@ngx-formly/core";
import { ViewCourseProviderComponent } from './course-provider/view-course-provider/view-course-provider.component';
import { SharedModule } from "src/app/framework/shared/shared.module";
import { CourseComponent } from './course/course.component';
import { AddEditCourseComponent } from './course/add-edit-course/add-edit-course.component';
import { ViewCourseComponent } from './course/view-course/view-course.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { AddEditEventDetailsComponent } from './event-details/add-edit-event-details/add-edit-event-details.component';
import { ViewEventDetailsComponent } from './event-details/view-event-details/view-event-details.component';
import { CourseDeliveryComponent } from './course-delivery/course-delivery.component';
import { AddEditCourseDeliveryComponent } from './course-delivery/add-edit-course-delivery/add-edit-course-delivery.component';
import { ViewCourseDeliveryComponent } from './course-delivery/view-course-delivery/view-course-delivery.component';
import { EventDeliveryComponent } from './event-delivery/event-delivery.component';
import { AddEditEventDeliveryComponent } from "./event-delivery/add-edit-event-delivery/add-edit-event-delivery.component";
import { ViewEventDeliveryComponent } from "./event-delivery/view-event-delivery/view-event-delivery.component";
import { DeliveryClashComponent } from './delivery-clash/delivery-clash.component';
import { CourseDeliveryTabComponent } from './course-delivery/course-delivery-tab/course-delivery-tab.component';
import { RequestBookingComponent } from "./course-delivery/request-booking/request-booking.component";
import { ConfirmBookingComponent } from "./course-delivery/confirm-booking/confirm-booking.component";
import { ViewBookingDetailsComponent } from "./course-delivery/view-booking-details/view-booking-details.component";
import { MarkAttendanceCorceDeliveryComponent } from './course-delivery/mark-attendance-corce-delivery/mark-attendance-corce-delivery.component';
import { MarkAttendanceTabComponent } from './course-delivery/mark-attendance-tab/mark-attendance-tab.component';
import { WorkshopConfirmBookingComponent } from "./workshop-delivery/workshop-confirm-booking/workshop-confirm-booking.component";
import { ViewWorkshopBookingDetailsComponent } from "./workshop-delivery/view-workshop-booking-details/view-workshop-booking-details.component";
import { WorkshopDeliveryTabComponent } from "./workshop-delivery/workshop-delivery-tab/workshop-delivery-tab.component";
import { WorkshopComponent } from './workshop/workshop.component';
import { WorkshopDeliveryComponent } from './workshop-delivery/workshop-delivery.component';
import { AddEditWorkshopComponent } from './workshop/add-edit-workshop/add-edit-workshop.component';
import { ViewWorkshopComponent } from "./workshop/view-workshop/view-workshop.component";
import { AddEditWorkshopDeliveryComponent } from './workshop-delivery/add-edit-workshop-delivery/add-edit-workshop-delivery.component';
import { ViewWorkshopDeliveryComponent } from './workshop-delivery/view-workshop-delivery/view-workshop-delivery.component';
import { EventDeliveryTabComponent } from "./event-delivery/event-delivery-tab/event-delivery-tab.component";
import { EventConfirmBookingComponent } from "./event-delivery/event-confirm-booking/event-confirm-booking.component";
import { ViewEventBookingDetailsComponent } from "./event-delivery/view-event-booking-details/view-event-booking-details.component";
import { PopUpComponent } from "./pop-up/pop-up.component";
import { ConfirmDeleteDialogPopupComponent } from './course-delivery/confirm-delete-dialog-popup/confirm-delete-dialog-popup.component';
import { EventDeliveryMarkAttendanceComponent } from './event-delivery/event-delivery-mark-attendance/event-delivery-mark-attendance.component';
import { WorkshopDeliveryMarkAttendanceComponent } from './workshop-delivery/workshop-delivery-mark-attendance/workshop-delivery-mark-attendance.component';
import { WorkshopRequestBookingComponent } from "./workshop-delivery/workshop-request-booking/workshop-request-booking.component";
import { ViewValidatorBookingDetailsComponent } from "./course-delivery/view-validator-booking-details/view-validator-booking-details.component";
import { CancelCourseDeliveryPopUpComponent } from './course-delivery/view-booking-details/cancel-course-delivery-pop-up/cancel-course-delivery-pop-up.component';
import { BookingRefundComponent } from './course-delivery/view-booking-details/booking-refund/booking-refund.component';
import { SessionsService } from "../shared/services/sessions.service";
import { ManullyAssignCourseComponent } from "./course-delivery/manully-assign-course/manully-assign-course.component";
import { AssignCoursePopupComponent } from './course-delivery/manully-assign-course/assign-course-popup/assign-course-popup.component';
import { WaitingListComponent } from './course-delivery/waiting-list/waiting-list.component';
import { ManullyAssignWorkshopComponent } from "./workshop-delivery/manully-assign-workshop/manully-assign-workshop.component";
import { ManualAssignEventComponent } from './event-delivery/manual-assign-event/manual-assign-event.component';

const config: ConfigOption = {
  validators: [
    { name: 'minDate', validation: minDateValidator(new Date(new Date().getTime() )) },
    { name: 'invalidDate', validation: invalidDateValidator() },
  ],
};

@NgModule({
  declarations: [
    CourseManagementComponent,
    CourseProviderComponent,
    CourseLocationComponent,
    AddCourseLocationComponent,
    ViewCourseLocaionComponent,
    AddEditCourseProviderComponent,
    ViewCourseProviderComponent,
    CourseComponent,
    AddEditCourseComponent,
    ViewCourseComponent,
    EventDetailsComponent,
    AddEditEventDetailsComponent,
    ViewEventDetailsComponent,
    CourseDeliveryComponent,
    AddEditCourseDeliveryComponent,
    ViewCourseDeliveryComponent,
    EventDeliveryComponent,
    AddEditEventDeliveryComponent,
    ViewEventDeliveryComponent,
    DeliveryClashComponent,
    CourseDeliveryTabComponent,
    RequestBookingComponent,
    ConfirmBookingComponent,
    ViewBookingDetailsComponent,
    PopUpComponent,
    MarkAttendanceCorceDeliveryComponent,
    MarkAttendanceTabComponent,
    ViewValidatorBookingDetailsComponent,
    MarkAttendanceTabComponent,
    WorkshopConfirmBookingComponent,
    WorkshopDeliveryTabComponent,
    WorkshopConfirmBookingComponent,
    ViewWorkshopBookingDetailsComponent,
    WorkshopComponent,
    WorkshopDeliveryComponent,
    AddEditWorkshopComponent,
    ViewWorkshopComponent,
    AddEditWorkshopDeliveryComponent,
    ViewWorkshopDeliveryComponent,
    EventDeliveryTabComponent,
    EventConfirmBookingComponent,
    ViewEventBookingDetailsComponent,
    ConfirmDeleteDialogPopupComponent,
    EventDeliveryMarkAttendanceComponent,
    WorkshopDeliveryMarkAttendanceComponent,
    WorkshopRequestBookingComponent,
    ManullyAssignCourseComponent,
    AssignCoursePopupComponent,
    WaitingListComponent,
    CancelCourseDeliveryPopUpComponent,
    BookingRefundComponent,
    ManullyAssignWorkshopComponent,
    ManualAssignEventComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    CourseManagementRoutingModule,
    DynamicTableModule,
    MatInputModule,
    MatChipsModule,
    QuillModule.forRoot(),
    DynamicFormsModule,
    FormlyModule.forRoot(config),
  ],
  providers: [
    CourseManagementNavigation,
    DatePipe,
    SessionsService 
  ]
})
export class 
CourseManagementModule { }


function invalidDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const controlDate = new Date(control.value);
    return isNaN(controlDate.getTime()) ? { invalidDate: { value: control.value, message: 'Invalid delivery date.' } } : null;
  };
}

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const controlDate = new Date(control.value);
    return controlDate < minDate ? { minDate: { value: control.value, message: 'Date should be in tomorrow or later.' } } : null;
  };
}

