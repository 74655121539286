import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { tap } from 'rxjs';
import { SessionsService } from 'src/app/features/shared/services/sessions.service';
import { ILearners } from 'src/app/features/captr-learners/learners.interface';
import { CaptrLearnersService } from 'src/app/features/captr-learners/captr-learners.services';
import { CourseManagementService } from '../../course-management.service';
import { MatDialog } from '@angular/material/dialog';
import { AssignCoursePopupComponent } from './assign-course-popup/assign-course-popup.component';
import { isThisQuarter } from 'date-fns';
import { CourseManagementNavigation } from '../../course-management-nav';

@Component({
  selector: 'app-manully-assign-course',
  templateUrl: './manully-assign-course.component.html',
  styleUrls: ['./manully-assign-course.component.scss']
})
export class ManullyAssignCourseComponent  implements OnInit, AfterViewInit, OnDestroy {

  dataSource: any = new MatTableDataSource<ILearners>();
  displayedColumns: string[] = ['FullName', 'ServiceNumber','Programme','Status', 'RRCAllocated', 'careerConsultant', 'assign'];
  pageSize = 25;
  filterBy = { 'keyword': '', 'refUserType': 'SU', appId: localStorage.getItem('suAppId') };
  sortColumn = 'FullName';
  sortDirection = 'asc';
  showFilteredList: boolean = false;
  filteredListSize: any;
  searchTerm: string = '';
  identifier: any;
  courseName: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  userType: number = 3;
  serviceUserId: any;
  isServiceUserSelected: boolean = false;
  isActive;
  courseType: any;

  constructor(private readonly inPageNavService: InPageNavService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackBarService: SnackBarService,
    private readonly captrLearnersService: CaptrLearnersService,
    private readonly courseManagementService: CourseManagementService,
    private readonly appConfirmService: AppConfirmService,
    private readonly sessionService: SessionsService,
    public dialog: MatDialog,
    private readonly courseNavigation: CourseManagementNavigation) {
      this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
      this.activatedRoute.queryParams.subscribe((params: any) => {
        this.identifier = params.id;
        this.courseName=params.courseName;
        this.isActive = params.hideEdit;
        this.courseType=params.courseType;
      });
  }

  ngOnInit(): void {
    this.resolveUsers(this.filterBy);

  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => {
      this.sortColumn = data.active;
      this.sortDirection = data.direction;
      this.paginator.pageIndex = 0;
      this.resolveUsers(this.filterBy);
    });

    this.paginator.page
      .pipe(
        tap(() => {
          this.resolveUsers(this.filterBy);
          document.querySelector('#view-programmes').scrollIntoView();
        }
        )
      )
      .subscribe();
  }

  onFilter(filterString: string) {
    this.showFilteredList = false;
    this.filterBy.keyword = filterString;
    this.paginator.pageIndex = 0;
    this.resolveUsers(this.filterBy);
  }
  resolveUsers(filterBy) {
    this.showFilteredList = false;
    let currentPageIndex = 0;
    if (!this.paginator) {
      currentPageIndex = 0;
    } else {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.getAllSUUserPaginated(this.identifier,`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
      .subscribe(data => {
        data.content.forEach(element => {
          element.fullName = element.fullName;
        });
        this.dataSource.data = data.content;
        this.paginator.length = data.totalElements;
      },
        error => {
          this.snackBarService.error(`${error.error.applicationMessage}`);
          this.router.navigate(['./captr-learners']);
        });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  resolveFilteredServiceUsers(filterBy) {
    let currentPageIndex = 0;
    if (!this.paginator) {
      currentPageIndex = 0;
    } else {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.captrLearnersService.getFilteredSUList(`${this.sortColumn},${this.sortDirection}`, this.paginator.pageSize, currentPageIndex, filterBy).subscribe(data => {
      data.content.forEach(element => {
        element.workerfirstname = element.workerName.split(' ')[0];
      });
      this.dataSource.data = data.content;
      this.paginator.length = data.totalElements;
      this.filteredListSize = data.totalElements;
    })
  }

  onClickClose() {
    this.searchTerm = '';
    this.filterBy.keyword =this.searchTerm
    this.resolveUsers(this.filterBy);
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }

  navigateToRoute(){
    this.router.navigate([`courses-events/courseDeliveries`]);
  }

  onCheckAssign(data) {
    if (data.checked) {
      this.isServiceUserSelected = true;
      this.serviceUserId = data.source.value;
    }else {
      this.isServiceUserSelected = false;
    }
  }

  onConfrimClick(serviceUserId){
    let payload = {
      deliveryId : this.identifier,
      serviceUserId: serviceUserId,
      hideEdit : this.isActive,
      courseType:this.courseType
    };
    const dialogRef = this.dialog.open(AssignCoursePopupComponent, {
      backdropClass: 'cdk-overlay-dark-backdrop',
      data: payload,
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          console.log("Result==" ,result);
      }
    });
  }

}