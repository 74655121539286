import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionsService } from '../../sessions/sessions.service';


@Injectable()
export class AuthorizationGuard  {

    constructor(
        protected router: Router,
        protected sessionsService: SessionsService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.sessionsService.hasResource(route.data['auth']) || this.sessionsService.hasResource(route.data['auth2nd'])) {
            return true;
        }
        this.sessionsService.signout().subscribe();
        return false;
    }

    canActivateChild(childroute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.canActivate(childroute, state);
    }

}


