import { Component, OnInit } from '@angular/core';
import { CourseManagementNavigation } from '../course-management-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { StateManagementService } from 'src/app/framework/state-management/state-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course-delivery',
  templateUrl: './course-delivery.component.html'
})
export class CourseDeliveryComponent implements OnInit {
  constructor(
    private readonly inPageNavService: InPageNavService,
    private router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly smService: StateManagementService
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }
  collection = 'courseDeliveries'; 
  fields = ['courses.courseName', 'courseDeliveryId', 'ref_delivery_types.name', 'location.locationName', 'room_location.roomName', 'deliveryStartDate'];
  searchFields = ['courses.courseName', 'courseDeliveryId'];
  buttonLable = "course delivery";
  baseRoute = '/courses-events';
  sortBy="deliveryStartDate";
  archiveTab='1'

  collection1 = 'courseDeliveries'
  fields1 = ['courses.courseName', 'courseDeliveryId', 'ref_delivery_types.name', 'location.locationName', 'room_location.roomName', 'deliveryStartDate','courseDeliveryCancelledDate'];
  searchFields1 = ['courses.courseName', 'courseDeliveryId'];
  sortBy1 = "courseDeliveryCancelledDate"
  archiveTab1 = '2';

  archiveTab2 = '3';

  archiveTab3 = '4';

  activeIndex : any = 0;
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async ngOnInit() {
    
    this.activeIndex = await this.smService.getStoreData('activeSubTab');
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  async onTabChanged(tabIndex){

    let previousStateTabIndex = await this.smService.getStoreData('activeSubTab');
    if(previousStateTabIndex != tabIndex){

      this.smService.resetSubNavigationStateData(false);
    }
    this.smService.setactiveSubTab(tabIndex); 
  }
}
