import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionsService } from 'src/app/features/shared/services/sessions.service';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { environment } from 'src/environments/environment';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CourseManagementService } from '../../course-management.service';
import { PopUpComponent } from '../../pop-up/pop-up.component';
import { IBooking } from '../booking.interface';

@Component({
  selector: 'app-view-validator-booking-details',
  templateUrl: './view-validator-booking-details.component.html',
  styleUrls: ['./view-validator-booking-details.component.scss']

})
export class ViewValidatorBookingDetailsComponent implements OnInit {
  identifier: any;
  cdId: any;
  pageName: any;
  routeIntent: any;
  booking: IBooking;
  isPaymentSectionEnable = false;
  elcasDetailsForm: FormGroup;
  elcasDocName: any;
  uploadDisable: Boolean = false;
  elcasDecumnetFile: File | null = null;
  bookingType: number;
  isELCASFunding: boolean;
  filePath;
  fileName;

  isDocumentUploaded = false;
  isActive = false;
  isShowReplaceBtn: Boolean = false;
  invalidFileName: Boolean = false;
  invalidFileSize: boolean;

  fileTypes: string[] = [
    'application/pdf',
  ];
  displayedColumns: string[] = ['type', 'amount', 'status', 'paymentDate'];
  dataSource = new MatTableDataSource<IBooking>();

  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly appConfirmService: AppConfirmService,
    private readonly courseManagementService: CourseManagementService,
    private dilogref: MatDialog,
    private readonly sessionService: SessionsService,) {
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.cdId = params.cdID;
      this.pageName = params.name;
      this.isActive = params.hideEdit;
    });
  }
  ngOnInit(): void {
    this.elcasForm();
    this.resolveBooking();
  }

  updateValidators(): void {
    const claimNumberControl = this.elcasDetailsForm.get('claimNumber');
    const elcContributionControl = this.elcasDetailsForm.get('ELCContribution');

    if (this.isDocumentUploaded) {
      claimNumberControl.setValidators(Validators.required);
      elcContributionControl.setValidators(Validators.required);
    } else {
      claimNumberControl.clearValidators();
      elcContributionControl.clearValidators();
    }

    claimNumberControl.updateValueAndValidity();
    elcContributionControl.updateValueAndValidity();
  }

  elcasForm(): void {
    this.elcasDetailsForm = this.fb.group({
      id: [null],
      claimNumber: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      ELCContribution: ['', [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]{1,2})?$')]],
      multipleCourses: [false],
      file: [null],
    });
  }

  resolveBooking() {
    this.route.params.subscribe((params: any) => {
      const id = params.id;
      this.courseManagementService.getBookingDetails(this.identifier).subscribe(bookingData => {
        this.booking = bookingData;
        this.bookingType = this.booking.bookingTypeId;

        this.dataSource = bookingData.paymentDetailsDTO;
        this.validateELCASDetails(this.booking);
        if (this.isELCASFunding) {
          this.patchValues(bookingData);
        }
      })
    })
  }
  validateELCASDetails(bookingDetails) {
    if (bookingDetails.elcasdetailsDTO != null) {
      this.isELCASFunding = bookingDetails.elcasdetailsDTO?.isSLUsingELCASFunding
    }
  }

  onRejectBookingClick() {
    let payload = {
      "id": this.identifier,
      "serviceLeaverId": this.booking?.serviceUserId,
      "courseId": this.booking?.courseId,
      "courseDeliveryId": this.cdId,
      "reasonId": null,
      "bookingType": "Course",
      "hideEdit": this.isActive,
      "bookingStatus": this.booking?.bookingStatus,
      "bookingTypeId": this.booking.bookingTypeId,
    };
    const dialogRef = this.dilogref.open(PopUpComponent, {
      backdropClass: 'disable-backdrop',
      data: payload,
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe(confirmed => {

    })
  }

  approveNCFBooking(data) {
    const elcasAmount = data?.ELCContribution;
    if (elcasAmount > 2000 || elcasAmount > this.booking.courseFees) {
      this.snackBarService.error("ELCAS amount shoud be less or equal to course fee");
    } else {
      let formData = new FormData();
      if (this.isELCASFunding) {
        formData = this.parseData(this.elcasDecumnetFile);
      }
      let postData = {
        "validatorCANNumber": data?.claimNumber,
        "validatorELCASAmount": data?.ELCContribution,
        "bookingUserMappingId": this.identifier
      };
      formData.append('dto', JSON.stringify(postData));
      this.courseManagementService.validatorApproveNCFBooking(formData).then(response => {
        this.snackBarService.success('Booking approved successfully');
        this.router.navigate(
          ['/courses-events/courseDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.cdId,
              operation: 'view',
              hideEdit: this.isActive,
            }
          })
      }, error => {
        this.snackBarService.error(`${error.error.applicationMessage}`);
      });
    }

  }

  private parseData(file: File): FormData {
    const formData = new FormData();
    if (file != null) {
      formData.append('file', file);
    }
    return formData;
  }

  selectFile(event) {
    this.filePath = null;
    this.elcasDocName = null;

    if (event.target.files.length > 0) {
        const file = event.target.files[0];
        
        if (file.name.length > 255) {
            this.invalidFileName = true;
            return;
        }

        // Check if the file size exceeds 5 MB
        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            this.invalidFileSize = true;
            return;
        }else{
          this.invalidFileSize = false;
        }

        // Check if the file type is valid
        if (this.validFileType(file)) {
            this.uploadDisable = false;
            this.elcasDecumnetFile = file;
            this.elcasDocName = file.name;
        } else {
            this.uploadDisable = true;
        }
    }
}

  validFileType(file) {
    for (let i = 0; i < this.fileTypes.length; i++) {
      if (file.type === this.fileTypes[i]) {
        return true;
      }
    }
    return false;
  }
  
  patchValues(bookingData) {
    this.filePath = bookingData.elcasdetailsDTO.documentPath;
    this.fileName = bookingData.elcasdetailsDTO.documentName;
    
    const formData = {
      claimNumber: bookingData.elcasdetailsDTO.canNumber || '',
      ELCContribution: bookingData.elcasdetailsDTO.elcasAmount ? bookingData.elcasdetailsDTO.elcasAmount.toFixed(2) : '',
      multipleCourses: bookingData.elcasdetailsDTO.isCanMultipleCourses,
    };
    this.elcasDetailsForm.patchValue(formData);
    this.elcasDetailsForm.get('multipleCourses').disable()
    if (bookingData.elcasdetailsDTO.isCanMultipleCourses == false) {
      this.elcasDetailsForm.get('multipleCourses').setValue('false')
    }
    else {
      this.elcasDetailsForm.get('multipleCourses').setValue('true')
    }
    if(this.elcasDocName != null || this.elcasDocName != ''){
      this.isShowReplaceBtn = true;
    }
    this.elcasDocName = bookingData.elcasdetailsDTO.documentName || '';
  }

  openApproveBookingDialog(value) {
    if (this.elcasDetailsForm.valid && value.ELCContribution !== "" && value.claimNumber !== "") {
      const dialogRef = this.appConfirmService.confirm({
        message: `Are you sure you want to approve?`,
        okButtonLabel: 'Approve',
        cancelButtonLabel: 'Close'
      });
      dialogRef.subscribe(result => {
        if (result) {
          this.approveNCFBooking(value);
        }
      });
    } else {
      this.snackBarService.error(`Invalid input values`);
    }
  }

  openRejectBookingDialog() {
    const dialogRef = this.appConfirmService.confirm({
      message: `Are you sure you want to reject?`,
      okButtonLabel: 'Reject',
      cancelButtonLabel: 'Close'
    });
    dialogRef.subscribe(result => {
      if (result) {
        this.onRejectBookingClick();
      }
    });
  }

  downloadFile(): void {
    const url = environment.cdnUrl + "/"+ this.filePath
    const fileName = this.fileName

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Download error:', error));
  }



  download(element): void {
    const blobPath = { 'path': this.filePath}
    this.courseManagementService.downloadAttendance(blobPath,this.identifier).subscribe((res:any) => {
      const safeUrl = res.path;
      const a = document.createElement("a");
      a.href = safeUrl as string;
      a.download = "";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
      (error) => {
        this.snackBarService.error(error.error.applicationMessage);
        console.error("Error downloading the file.", error);
      });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  getStatusForPayment(status){
  
    if (status== 'Success') {
        return 'Paid'
    } else if (status== 'Pending') {
      return 'N/A'
    }else if (status== 'In Progress') {
      return 'In Progress'
    } else {
      return 'Pending';
    }

  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }
}
