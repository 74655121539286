<mat-card id='view-dynamic-table' [ngClass]="{'mat-table-onview': !onlyList}">
  <mat-card-header>
    <span class="example-form" *ngIf="onlyList"  #usersContainer>
      <mat-form-field id="search_service_user_form" class="example-full-width" appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput type="text" [(ngModel)]="searchTerm" (keydown.enter)="applySearch()" placeholder="Search by">
        <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
          <mat-icon>search</mat-icon>
        </button>
        <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClose()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </span>
    <span class="spacer"></span>
    <span *ngIf="addButtonLable" [ngClass]="{'mb-20': !onlyList}">
      <button *ngIf="isAuthorized('create')" id="add_{{addButtonLable}}_button" mat-stroked-button class="text-right"
        (click)="onClickRow(collection,onlyList)">Add {{addButtonLable}}</button>
    </span>
  </mat-card-header>
  <mat-card-content style="padding-left: 0px;padding-right: 0px;">
    <div>
      <table mat-table [dataSource]="dataSource" matSort aria-describedby="dynamic table">
        <!-- Dynamically Generated Columns -->
        <ng-container *ngFor="let field of fields" [matColumnDef]="field">
          <div *ngIf="field === 'ref_delivery_types.name' || field === 'ref_wsdl_types.name'">
            <th class="dy-data-width" id="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Delivery type</th>
          </div>
          <div *ngIf="field === 'ref_course_course_type.name'">
            <th class="dy-data-width" id="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Workshop type
            </th>
          </div>
          <div *ngIf="!(field === 'ref_delivery_types.name' || field === 'ref_wsdl_types.name')">
            <th class="dy-data-width" id="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ field === 'parentLocation' ? 'Parent location?' : transformCamelCaseToReadable(field) }} </th>
          </div>
          <td class="dy-data-width" *matCellDef="let row">
            <span class="sub-level" *ngIf="isBoolean(getNestedProperty(row, field))"
              [color]="getNestedProperty(row, field) ? 'primary' : 'accent'">
              {{ getNestedProperty(row, field) ? 'Yes' : 'No' }}
            </span>
            <span *ngIf="!isBoolean(getNestedProperty(row, field))">{{ getNestedProperty(row, field) }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th id="action-header" mat-header-cell *matHeaderCellDef style="padding-left: 25px;">Actions</th>
          <td mat-cell *matCellDef="let row">
            <button *ngIf="isAuthorized('view') && onlyList" [matTooltipShowDelay]="800" matTooltip="View"
              aria-label="view button" mat-icon-button color="primary"
              (click)="onViewClickRow(collection,row.id,publicationState,row.attributes)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button *ngIf="isAuthorized('update') && showEdit" [matTooltipShowDelay]="800" matTooltip="Edit"
              aria-label="edit button" mat-icon-button color="primary"
              (click)="onEditClickRow(collection,row.id,publicationState,row.attributes,onlyList)">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="isAuthorized('delete') && showDelete" [matTooltipShowDelay]="800" matTooltip="Delete"
              aria-label="delete button" mat-icon-button (click)="onDeleteConfirmationClick(collection, row.id)"
              class="background-color" [disabled]="isDeleteDisabled(collection,row.id)">
              <mat-icon>delete</mat-icon>
            </button>
            <button *ngIf="isAuthorized('attendence') && showMarkAttendence" [matTooltipShowDelay]="800"
              matTooltip="Attendance" aria-label="Attendance button" mat-icon-button color="primary"
              (click)="onMarkAttendance(collection,row.id,publicationState,row.attributes,onlyList)"
              [disabled]="!canMarkAttendance(row.attributes.deliveryStartDate)">
              <mat-icon>people_outline</mat-icon>
            </button>
            <button *ngIf="isAuthorized('overwrite') && showOverwrite" [matTooltipShowDelay]="800" matTooltip="Repost for new applicants"
              aria-label="edit button" mat-icon-button color="primary"
              (click)="onOverWriteClick(collection,row.id)">
              <mat-icon>queue</mat-icon>
            </button>
            <button *ngIf="isAuthorizedCheck(193,1) && isAssignCourse" [matTooltipShowDelay]="800"
              matTooltip="Assign SL to booking delivery" aria-label="edit button" mat-icon-button color="primary"
              (click)="onAssignCourse(collection,row.id,publicationState,row.attributes,onlyList)"
              [disabled]="!canAssignCourse(row.attributes.deliveryStartDate)">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 136.000000 103.000000" class="svg-icon"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)"
                fill="primaryAppColour" stroke="none">
                <path d="M524 861 c-103 -63 -120 -197 -33 -275 l38 -35 -34 -11 c-46 -15
                -122 -90 -145 -144 -29 -65 -37 -150 -16 -181 l16 -25 170 0 c143 0 170 2 170
                15 0 12 -27 15 -162 17 l-163 3 -3 52 c-6 101 76 207 184 239 53 15 61 15 113
                0 76 -23 86 -23 86 -6 0 8 -15 22 -33 30 l-33 16 24 13 c65 35 89 165 44 232
                -51 76 -152 103 -223 60z m166 -50 c61 -45 63 -142 3 -198 -30 -29 -41 -33
                -88 -33 -64 0 -107 30 -125 86 -42 127 102 226 210 145z"/>
                <path d="M770 465 l0 -35 -78 0 c-47 0 -83 -5 -90 -12 -7 -7 -12 -39 -12 -74
                0 -85 11 -94 106 -94 l74 0 0 -36 c0 -26 4 -35 15 -32 18 4 182 138 189 155 5
                13 -170 163 -191 163 -8 0 -13 -13 -13 -35z m150 -124 c0 -9 -98 -91 -109 -91
                -5 0 -11 10 -13 23 -3 20 -9 22 -86 25 l-83 3 3 42 3 42 83 3 c71 2 82 5 82
                21 0 10 3 20 6 24 6 6 114 -81 114 -92z"/>
                </g>
            </svg>

            </button>

            <button *ngIf="isAuthorizedCheck(204,1) && isAssignEvent" [matTooltipShowDelay]="800"
            matTooltip="Assign SL to booking delivery" aria-label="edit button" mat-icon-button color="primary"
            (click)="onManualEventAssign(collection,row.id,publicationState,row.attributes,onlyList)">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 136.000000 103.000000" class="svg-icon"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)"
              fill="primaryAppColour" stroke="none">
              <path d="M524 861 c-103 -63 -120 -197 -33 -275 l38 -35 -34 -11 c-46 -15
              -122 -90 -145 -144 -29 -65 -37 -150 -16 -181 l16 -25 170 0 c143 0 170 2 170
              15 0 12 -27 15 -162 17 l-163 3 -3 52 c-6 101 76 207 184 239 53 15 61 15 113
              0 76 -23 86 -23 86 -6 0 8 -15 22 -33 30 l-33 16 24 13 c65 35 89 165 44 232
              -51 76 -152 103 -223 60z m166 -50 c61 -45 63 -142 3 -198 -30 -29 -41 -33
              -88 -33 -64 0 -107 30 -125 86 -42 127 102 226 210 145z"/>
              <path d="M770 465 l0 -35 -78 0 c-47 0 -83 -5 -90 -12 -7 -7 -12 -39 -12 -74
              0 -85 11 -94 106 -94 l74 0 0 -36 c0 -26 4 -35 15 -32 18 4 182 138 189 155 5
              13 -170 163 -191 163 -8 0 -13 -13 -13 -35z m150 -124 c0 -9 -98 -91 -109 -91
              -5 0 -11 10 -13 23 -3 20 -9 22 -86 25 l-83 3 3 42 3 42 83 3 c71 2 82 5 82
              21 0 10 3 20 6 24 6 6 114 -81 114 -92z"/>
              </g>
              </svg>
            </button>
            <button *ngIf="isAuthorizedCheck(203,1) && isAssignWorkshop" [matTooltipShowDelay]="800"
            matTooltip="Assign SL to booking delivery" aria-label="edit button" mat-icon-button color="primary"
            (click)="onAssignWorkshop(collection,row.id,publicationState,row.attributes,onlyList)">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 136.000000 103.000000" class="svg-icon"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)"
            fill="primaryAppColour" stroke="none">
            <path d="M524 861 c-103 -63 -120 -197 -33 -275 l38 -35 -34 -11 c-46 -15
            -122 -90 -145 -144 -29 -65 -37 -150 -16 -181 l16 -25 170 0 c143 0 170 2 170
            15 0 12 -27 15 -162 17 l-163 3 -3 52 c-6 101 76 207 184 239 53 15 61 15 113
            0 76 -23 86 -23 86 -6 0 8 -15 22 -33 30 l-33 16 24 13 c65 35 89 165 44 232
            -51 76 -152 103 -223 60z m166 -50 c61 -45 63 -142 3 -198 -30 -29 -41 -33
            -88 -33 -64 0 -107 30 -125 86 -42 127 102 226 210 145z"/>
            <path d="M770 465 l0 -35 -78 0 c-47 0 -83 -5 -90 -12 -7 -7 -12 -39 -12 -74
            0 -85 11 -94 106 -94 l74 0 0 -36 c0 -26 4 -35 15 -32 18 4 182 138 189 155 5
            13 -170 163 -191 163 -8 0 -13 -13 -13 -35z m150 -124 c0 -9 -98 -91 -109 -91
            -5 0 -11 10 -13 23 -3 20 -9 22 -86 25 l-83 3 3 42 3 42 83 3 c71 2 82 5 82
            21 0 10 3 20 6 24 6 6 114 -81 114 -92z"/>
            </g>
            </svg>
          </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="fields.concat('actions')"></tr>
        <tr mat-row *matRowDef="let row; columns: fields.concat('actions');"></tr>
      </table>
      <div class="hint-message-table"
        *ngIf="!dataSource || !dataSource.data || dataSource.data.length === 0 && !onlyList">
        <p>No rooms have been added yet.</p>
      </div>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" aria-label="Select page"></mat-paginator>
    </div>
  </mat-card-content>

</mat-card>