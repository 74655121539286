<mat-card id="view-booking">
    <mat-card-header>
        <span class="action-buttons">
            <button id="exit_button" mat-stroked-button
                [routerLink]="confirmedExitBtn ? ['../confirm-booking'] : ['../booking-request']"
                [queryParams]="{ id: cdId, operation: 'view',hideEdit: isActive }">
                Exit
            </button>
        </span>
    </mat-card-header>
    <mat-card-content>
        <dl>
            <div class="label-hr">
                <span>Course booking details</span>
            </div>
            <div>
                <dt class="details-label">Course name</dt>
                <dd>{{ booking?.courseName || "N/A"}}</dd>
            </div>

            <div>
                <dt class="details-label">Service leaver name</dt>
                <dd>{{ booking?.serviceLeaverName || "N/A"}}</dd>
            </div>

            <div>
                <dt class="details-label">Email address</dt>
                <dd>{{ booking?.email || "N/A" }}</dd>
            </div>
            <dl>
                <dt class="details-label">Rank</dt>
                <dd>{{ booking?.rankName || "N/A"}}</dd>
            </dl>
            <dl>
                <dt class="details-label">Length of service</dt>
                <dd>{{ booking?.lengthOfService || "N/A"}}</dd>
            </dl>
            <dl>
                <dt class="details-label">Entitlement to CTP service name</dt>
                <dd>{{ booking?.entitlementToCTPServiceName || "N/A"}}</dd>
            </dl>
            <dl *ngIf="isRejectReason">
                <dt class="details-label">Status</dt>
                <dd>{{ booking?.bookingStatus || "N/A"}}</dd>
            </dl>
            <dl *ngIf="isRejectReason">
                <dt class="details-label">Status reason</dt>
                <dd>{{ booking?.rejectedReason || "N/A"}}</dd>
            </dl>
            <div class="label-hr">
                <span>Transaction history</span>
            </div>
            <div class="card-row">
                <form [formGroup]="confirmBooking" validate autocomplete="off">
                    <div class="card-row">
                        <mat-label>Do you want to refund booking ?</mat-label>
                        <mat-radio-group formControlName="isconfirmBooking" name="isconfirmBooking" required (change)="onConfirmBookingChange()">
                            <div class="radio-button-container">
                                <mat-radio-button value=true>Yes</mat-radio-button>
                                <mat-radio-button value=false>No</mat-radio-button>
                            </div>
                        </mat-radio-group>
            
                    </div>
                </form>
            </div>
            <div class="card-row">
                <form #testForm="ngForm"  (ngSubmit)="onSubmit(testForm)" novalidate>
                    <mat-card-content>
                        <mat-table id="content_modules_table" [dataSource]="dataSource" >

                            <ng-container matColumnDef="paymentType">
                                <mat-header-cell *matHeaderCellDef id="type_button" style="font-weight: bold;">Type
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.paymentType}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="paymentAmount">
                                <mat-header-cell *matHeaderCellDef id="amount_button" style="font-weight: bold;">Amount
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.paymentAmount || "N/A" | currency:'GBP':'symbol':'1.2-2'}}</mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef id="status_button" style="font-weight: bold;">Status
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.paymentDescription === 'Success' ? 'Paid' : 'Pending'}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="paymentDate">
                                <mat-header-cell *matHeaderCellDef id="paymentDate_button" style="font-weight: bold;">
                                    Payment date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.paymentDate ? (element.paymentDate | date: 'dd/MM/yyyy') : 'N/A' }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="refund">
                                <mat-header-cell *matHeaderCellDef id="refund_button" style="font-weight: bold;">Refund
                                </mat-header-cell>
                                
                                <mat-cell *matCellDef="let element; let rowIdx = index;">
                                    <mat-checkbox  [(ngModel)]="element.refund"
                                        [checked]="element.refund" (change)="refundChanged($event, element)" name="refund{{rowIdx}}" [disabled]="element.paymentDescription!='Success' || !isRefundEnabled "> </mat-checkbox>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="refundDate">
                                <mat-header-cell *matHeaderCellDef id="refundDate_button">Refund date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element ; let rowIdx = index;" class="m-20">
                                    <mat-form-field >
                                        <input #refoundDate="ngModel"  matInput [matDatepicker]="refoundDates"
                                            [(ngModel)]="element.refundDate" name="refundDate{{rowIdx}}" required [disabled]="!element.refund || !isRefundEnabled">
                                        <mat-datepicker-toggle matSuffix [for]="refoundDates"></mat-datepicker-toggle>
                                        <mat-datepicker #refoundDates></mat-datepicker>
                                        <mat-error *ngIf="refoundDate?.invalid" class="refund-errors">
                                            <div *ngIf="refoundDate.errors.required">Refund date is required</div>
                                          </mat-error>
                                    </mat-form-field>
                                  
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="transactionId">
                                <mat-header-cell *matHeaderCellDef>
                                    Transaction ID
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let rowIdx = index;" class="m-20">
                                  <mat-form-field>
                                    <input matInput #transactionId="ngModel" name="transactionId{{rowIdx}}" placeholder="Transaction ID" [(ngModel)]="element.transactionId"
                                      required [disabled]="!element.refund || !isRefundEnabled">
                                    <mat-error *ngIf="transactionId?.invalid && transactionId?.touched" class="refund-errors">
                                      <div *ngIf="transactionId.errors.required">Transaction ID is required</div>
                                    </mat-error>
                                  </mat-form-field>
                                </mat-cell>
                              </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                    </mat-card-content>
                    <mat-card-actions>
                        <span class="spacer"></span>
                        <button *ngIf="isAuthorized(189,11)" mat-raised-button color="primary" type="submit" [disabled]="confirmBooking.invalid || testForm.invalid || (isRefundEnabled && !isAnyRefundSelected)">Save</button>
                        <button *ngIf="isAuthorized(189,11)" id="reject_button" type="button" (click)="onDiscontinuedClick()"  mat-raised-button color="primary" >Discontinue</button>     
                    </mat-card-actions>
                </form>
            </div>
        </dl>


    </mat-card-content>
</mat-card>