import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { StateManagementService } from 'src/app/framework/state-management/state-management.service';
import { CourseManagementNavigation } from '../course-management-nav';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent {

  collection = 'courses';
  fields = ['courseName', 'courseId', 'createdAt'];
  searchFields = ['courseName', 'courseId'];
  buttonLable = "course";
  baseRoute = '/courses-events';
  archiveTab = '2';
  archiveTab1 = '3';
  archiveTab2 = '5';
  activeIndex = 0;

  dynamicFilters: any = {
    publishedAt: { null: true },
  };

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly smService: StateManagementService
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }

  async ngOnInit() {
    this.activeIndex = await this.smService.getStoreData('activeSubTab');
  }

  async onTabChanged(tabIndex){
    let previousStateTabIndex = await this.smService.getStoreData('activeSubTab');
    if(previousStateTabIndex != tabIndex){
      this.smService.resetSubNavigationStateData(false);
    }
    this.smService.setactiveSubTab(tabIndex); 
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
