<mat-card id="view-booking">
    <mat-card-header>
        <span class="spacer"></span>
        <span class="action-buttons">
            <button id="exit_button" mat-stroked-button
                    [routerLink]="confirmedExitBtn ? ['../confirm-booking'] : ['../booking-request']"
                    [queryParams]="{ id: wdId, operation: 'view',hideEdit: isActive }">
              Exit
            </button>
        </span>
    </mat-card-header>
    <mat-card-content>
        <dl>
            <div class="label-hr">
                <span>Workshop booking details</span>
            </div>
            <dl>
                <dt class="details-label">Workshop name</dt>
                <dd>{{ booking?.workshopName || "N/A"}}</dd>
            </dl>
        
            <dl>
                <dt class="details-label">Service leaver name</dt>
                <dd>{{ booking?.serviceLeaverName || "N/A"}}</dd>
            </dl>
        
            <dl>
                <dt class="details-label">Email address</dt>
                <dd>{{ booking?.email || "N/A" }}</dd>
            </dl>
            <dl>
                <dt class="details-label">Rank</dt>
                <dd>{{ booking?.rankName || "N/A"}}</dd>
            </dl>
            <dl>
                <dt class="details-label">Length of service</dt>
                <dd>{{ booking?.lengthOfService || "N/A"}}</dd>
            </dl>
            <dl>
                <dt class="details-label">Entitlement to CTP service name</dt>
                <dd>{{ booking?.entitlementToCTPServiceName || "N/A"}}</dd>
            </dl>
        </dl>
        <dl *ngIf="isEnableRejectBtn || approveOrRejectBooking">
            <div class="label-hr">
                <span>Action</span>
            </div>
        </dl>
        <mat-card-footer>
            <span class="spacer"></span>
            <span class="action-buttons" *ngIf="isEnableRejectBtn && isAuthorized(188,11)">
                <button id="approve_button"(click)="onApproveBookingClick()" mat-raised-button color="primary">Approve booking</button>
                <button id="reject_button" type="button" (click)="onRejectBookingClick()" mat-stroked-button >Reject booking</button>  
            </span>
            <span class="action-buttons" *ngIf="approveOrRejectBooking && isAuthorized(188,11)">
                <button id="approve_button"(click)="cancelWorkshopBooking('Approve workshop cancellation')" mat-raised-button color="primary">Approve</button>
                <button id="reject_button" type="button" (click)="discountinedBookingClick()" mat-stroked-button >Discontinue</button>  
            </span>
            <span class="action-buttons" *ngIf="confirmBooking && isAuthorized(188,11)">
                <button id="approve_button"(click)="cancelWorkshopBookingByCC('Workshop cancellation')" mat-stroked-button color="primary">Cancel booking</button>
            </span>
          </mat-card-footer>
    </mat-card-content>
</mat-card>