import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Injectable } from "@angular/core";
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

parse(value: any): Date | null {
  if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      let year = str[2];
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      if (year.length > 4) {
        year = year.slice(0, 4);
      }
      return new Date(Number(year), month, date);
  } else if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
      const str = value.split('-');
      let year = str[0];
      const month = Number(str[1]) - 1;
      const date = Number(str[2]);
      if (year.length > 4) {
        year = year.slice(0, 4);
      }
      return new Date(Number(year), month, date);
  }
  const timestamp = typeof value === 'number' ? value : Date.parse(value);
  return isNaN(timestamp) ? null : new Date(timestamp);
}

format(date: Date, displayFormat: string): string {
   if (displayFormat === "input") {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
   } else if (displayFormat === "inputMonth") {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
      return  this._to2digit(month) + '/' + year;
   } else {
       const momentDate = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
       return momentDate.format(displayFormat);
   }
}

_to2digit(n: number) {
   return ('00' + n).slice(-2);
}
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


export const APP_DATE_FORMATS_WITH_TIME_STAMP = {
  parse: {
    dateInput: 'DD/MM/YYYY T HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY  HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const APP_MONTH_YEARS_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};