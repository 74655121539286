import { Component, ViewEncapsulation, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { DocumentData } from "src/app/data.interface";
import { GraphqlService } from "src/app/graphql.service";
import { DynamicTableService } from "./dynamic-table.service";
import { SnackBarService } from "../../service/snack-bar.service";
import { AppConfirmService } from "../app-confirm/app-confirm.service";
import { RoomPopupComponent } from "./room-popup/room-popup.component";
import { Observable, of } from "rxjs";
import { StateManagementService } from "../../state-management/state-management.service";
import { OverWritePopupComponent } from "./overWrite-popup/overWrite-popup.component";
import { SessionsService } from 'src/app/features/shared/services/sessions.service';
import { I } from "@angular/cdk/keycodes";

const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicTableComponent implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<DocumentData>([]);
  @Input() collection!: string;
  @Input() fields!: string[];
  @Input() filters!: any;
  @Input() publicationState?: 'LIVE' | 'PREVIEW';
  @Input() dynamicFilters: any[] = [];
  @Input() search!: string[];
  @Input() buttonLable: string;
  @Input() tabHead!: string;
  @Input() sortingBy!: string;
  @Input() baseRoute!: string;
  @Input() onlyList!: boolean;
  @Input() addFilter!: string;
  @Input()serviceView!:boolean;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  pageSizeOptions = [25, 50, 100];
  hidePageSizeSelector = false;

  clickedRows = new Set<DocumentData>();
  collectionData: any;
  searchTerm: string = '';
  oldFilter: any;
  createdDate: string;
  updatedDate: string;
  publishDate: string;
  addButtonLable: string;
  loggedInUserId: any;
  filterBy: { 'keyword': string, 'tabHead': string | number | boolean ,'addFilter': string | number | boolean };
  activeTab: any;
  filteredOptionsList: Observable<{ id: number; name: string }[]> = of([]);
  editData: any;
  showEdit: boolean = true;
  addOnFilter: any;
  showDelete: boolean = true;
  isPageIndexAsPerSearch: boolean = false;
  showMarkAttendence: boolean=false;
  isAssignCourse: boolean = false;
  isAssignWorkshop: boolean = false;

  allDataById: any;
  repostForNewApplications: any;
  showOverwrite: boolean;
  courseName: any;
  workshopName: any;
  featureMap : any= {
    "courseDeliveries" : {
      "fId":140,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13,
      "attendence": 40,
      "overwrite": 41
    },
    "eventDeliveries" : {
      "fId":157,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13,
      "attendence": 40
    },
    "events" : {
      "fId":150,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13
    },
    "courses" : {
      "fId":141,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13
    },
    "courseLocations" : {
      "fId":138,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
    },
    "providers" : {
      "fId":139,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "download": 12,
      "upload": 13,
    },
    "newsArticles" : {
      "fId":144,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "download": 12,
      "upload": 13,
    },
    "helpArticles" : {
      "fId":154,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
    },
    "jobs" : {
      "fId":175,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13,
    },
    "employers" : {
      "fId":191,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13
    },
    "dosServiceProviders" : {
      "fId":163,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
    },
    "dosServices" : {
      "fId":161,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "download": 12,
      "upload": 13,
    },
    "dosNationalServices" : {
      "fId":162,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "download": 12,
      "upload": 13,
    },
    "workshops" : {
      "fId":187,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13,
    },
    "workshopDeliveries" : {
      "fId":188,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
      "upload": 13,
      "attendence": 40
    },
    "roomLocations" : {
      "fId":158,
      "create" :1,
      "view":2,
      "update":3,
      "delete":4,
    }
  }
  @ViewChild('usersContainer') usersContainer: ElementRef<HTMLElement>;
  primaryAppColour: string;
  isAssignEvent: boolean = false;
  eventName: any;

  constructor(
    private graphqlService: GraphqlService,
    private readonly router: Router,
    private snackBar: MatSnackBar,
    private dilogref: MatDialog,
    private readonly dynamicTableService: DynamicTableService,
    private readonly appConfirmService: AppConfirmService,
    private readonly snackBarService: SnackBarService,
    private readonly smService: StateManagementService,
    private readonly sessionService: SessionsService
  ) {
    //window.scrollTo(0, 0);
    if (localStorage.getItem('jwt') != null) {
      this.loggedInUserId = JSON.parse(atob(localStorage.getItem('jwt')?.split('.')[1])).id
    }
    const storedPrimaryColor = localStorage.getItem('primaryAppColour');
    if (storedPrimaryColor) {
      this.primaryAppColour = storedPrimaryColor;
    }
  }

  ngOnInit(): void {   
    this.oldFilter = this.filters;
    this.addButtonLable = this.buttonLable;
    this.filterBy = this.filterBy;
    if (this.tabHead !== undefined && this.tabHead !== null) {
      if (typeof this.tabHead === 'boolean') {
        this.activeTab = [this.tabHead];
      } else if (typeof this.tabHead === 'string') {
        if (this.tabHead === 'true' || this.tabHead === 'false') {
          this.activeTab = [this.tabHead];
        } else if (this.tabHead.includes(',')) {
          this.activeTab = this.tabHead.split(',').map(value => value.trim() === 'true');
        } else {
          try {
            this.activeTab = JSON.parse(this.tabHead);
          } catch (error) {
            console.error('Invalid input for tabHead:', this.tabHead);
            this.activeTab = [];
          }
        }
      } else {
        console.error('Unsupported tabHead type:', typeof this.tabHead);
        this.activeTab = [];
      }
    }
    
    if(this.addFilter){
      this.addOnFilter = JSON.parse(this.addFilter);
      this.showEdit=false;
      this.showDelete=false;
    }
    this.sortingBy = this.sortingBy;
    this.baseRoute = this.baseRoute;
    if(((this.collection === 'courseDeliveries' || this.collection === 'eventDeliveries' || this.collection === 'workshopDeliveries') && this.tabHead==="true") ){
      this.showEdit=false;
    }
    else if (this.collection === 'courseDeliveries' && this.tabHead==="false" ){
      this.showOverwrite=true;
      this.isAssignCourse = true;
    }
    else if (this.collection === 'workshopDeliveries' && this.tabHead==="false" ){
      this.isAssignWorkshop = true;
    }
    else if (this.collection === 'eventDeliveries' && this.tabHead==="false" ){
      this.isAssignEvent = true;
    }
    if((this.collection === 'courseDeliveries' || this.collection==='eventDeliveries' || this.collection==='workshopDeliveries') && this.tabHead==="false"){
      this.showMarkAttendence=true;
    }
  }

 async ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filterBy = { 'keyword': this.searchTerm, 'tabHead': this.activeTab ,'addFilter':this.addOnFilter };

      this.fetchData(this.filterBy);
    });
    this.paginator?.page.subscribe(() =>{

      this.smService.setsubTabTablePageIndex(this.paginator.pageIndex); 
      this.fetchData(this.filterBy)
    });

    this.paginator.pageIndex = await this.smService.getStoreData('subTabTablePageIndex');
    this.searchTerm = await this.smService.getStoreData('subTabTableSearchQuery');
    if(this.searchTerm != '' && this.paginator.pageIndex > 0) this.isPageIndexAsPerSearch = true;
    this.fetchData(this.filterBy);
  }

  getNestedProperty(row: any, field: string): any {
    const segments = field.split('.');
    let value = row.attributes;
    for (const segment of segments) {
      if (typeof value === 'object') {
        if (segment in value) {
          if( value[segment]){
            value = value[segment];
          }else if(value[segment]==null){
            value='N/A'
          }else{
            value = value[segment];
          }
        } else if (value.data) {
          value = value.data;
          if (Array.isArray(value)) {
            const values = value.map(v => v.attributes.label).slice(0, 2);
            if (value.length > 3) {
              return `${values[0]}, ${values[1]}, +${value.length - 2} more`;
            }
            return values.join(', ');
          }
          if (value.attributes && segment in value.attributes) {
            value = value.attributes[segment];
          } else {
            return 'N/A';
          }
        } else {
          return 'N/A';
        }
      } else {
        return 'N/A';
      }
    }

    return value;
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  transformCamelCaseToReadable(field: string): string {
    if (field === 'createdAt') {
      field = 'dateAdded';
    }
    if (field === 'orgUniqueId') {
      field = 'organisationId';
    }
    if (field === 'eventType.name') {
      field = 'eventType';
    }
    if (field === 'ref_workshop_type.name') {
      field = 'workshopType';
    }
    if (field.includes('.')) {  
      const parts = field.split('.');
      let word = parts[parts.length - 1].split(/(?=[A-Z])/).map(word => word.charAt(0).toLowerCase() + word.slice(1));
      word[0]=word[0].charAt(0).toUpperCase()+word[0].slice(1);
      return `${word.join(' ')}`;
    }
    field = field.replace(/\./g, ' ');
    field = field.replace(/([A-Z])/g, ' $1');
    field = field.replace(/(^| )(\w)/g, (match) => match.toLowerCase());
    field = field.replace(/^\w/, (match) => match.toUpperCase());
    field = field.replace(/ id\b/g, ' ID');
    return field;
  }


  private fetchData(filterBy) {   

    filterBy = { 'keyword': this.searchTerm, 'tabHead': this.activeTab, 'addFilter':this.addOnFilter };
    let sort;
    const pagination = {
      page: this.paginator ? + this.paginator.pageIndex + 1 : 1,
      pageSize: this.paginator ? this.paginator.pageSize : 200,
    };

    if (this.sortingBy === 'createdAt') {
      sort = this.sort.active
        ? `${this.sort.active},${this.sort.direction}`
        : `${this.sortingBy},desc`;
    } else if (this.sortingBy === 'dateArchived') {
      sort = this.sort.active
        ? `${this.sort.active},${this.sort.direction}`
        : `${this.sortingBy},desc`;
    } else if (this.sortingBy === 'deliveryStartDate') {
      sort = this.sort.active
        ? `${this.sort.active},${this.sort.direction}`
        : `${this.sortingBy},asc`;
    }
    else if (this.sortingBy === 'courseDeliveryCancelledDate') {
      sort = this.sort.active
        ? `${this.sort.active},${this.sort.direction}`
        : `${this.sortingBy},desc`;
    }else if (this.sortingBy === 'eventDeliveryCancelledDate') {
      sort = this.sort.active
        ? `${this.sort.active},${this.sort.direction}`
        : `${this.sortingBy},desc`;
    }
    else {
      sort = this.sort.active
        ? `${this.sort.active},${this.sort.direction}`
        : `${this.fields[0]},asc`;
    }

    this.dynamicTableService
      .getGraphQLList(`${sort}`, pagination.page, pagination.pageSize, this.collection, filterBy)
      .subscribe((response: any) => {
        this.collectionData = response?.body.data[this.collection];
        if (this.collectionData?.data) {
          this.collectionData?.data?.forEach(element => {
            this.setDateInFormat(element);
          });
          this.hidePageSizeSelector = false;
          this.dataSource.data = this.collectionData?.data;
          this.paginator.length = this.collectionData?.meta.pagination.total;
          this.paginator.pageIndex = this.collectionData?.meta.pagination.page - 1;
          this.paginator.pageSize = this.collectionData?.meta.pagination.pageSize;
          this.hidePageSizeSelector = this.collectionData?.meta.pagination.total < Math.min(...this.pageSizeOptions);
          if(!this.serviceView){
            window.scrollTo(0, 0);
          }   
        }
        else {
          this.hidePageSizeSelector = true;
        }
      }, (errors) => {
        this.hidePageSizeSelector = true;
        this.snackBarService.error(errors?.error?.body?.errors[0]?.message);
      })
  }

  formatDate(dateString) {
    const originalDate = new Date(dateString);
    const day = originalDate.getUTCDate().toString().padStart(2, '0');
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = originalDate.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  setDateInFormat(element) {
    Object.keys(element.attributes).forEach(key => {
      const value = element.attributes[key];
      if (value && (key == 'createdAt' || key =='deliveryStartDate' || key == 'lastContact') || key.endsWith('CancelledDate')) {
        element.attributes[key] = this.formatDate(value);
      }
    });
  }

  applySearch() {
    this.searchTerm = this.searchTerm.replace(/\\/g, '');
    this.paginator.pageIndex = 0;
    this.smService.setsubTabTablePageIndex(this.paginator.pageIndex)
    this.smService.setsubTabTableSearchQuery(this.searchTerm); 
    this.filterBy = { 'keyword': this.searchTerm, 'tabHead': this.activeTab, 'addFilter':this.addOnFilter };
    this.dynamicFilters = [];
    this.fetchData(this.filterBy);
  }

  addDynamicFilter(field: string, operator: string, value: any) {
    if (!this.dynamicFilters) {
      this.dynamicFilters = [];
    }
    this.dynamicFilters.push({ [field]: { [operator]: value } });
  }

  onClickClose() {

    if(this.isPageIndexAsPerSearch === true && (this.searchTerm != '' && this.paginator.pageIndex > 0)){

      this.isPageIndexAsPerSearch = false;
      this.paginator.pageIndex = 0;
    }
    this.searchTerm = '';
    this.smService.setsubTabTableSearchQuery(this.searchTerm); 
    this.filterBy = { 'keyword': this.searchTerm, 'tabHead': this.activeTab, 'addFilter':this.addOnFilter };
    this.fetchData(this.filterBy);
  }

  isDeleteDisabled(data: any, id: any) {

  }


  onClickRow(data: any, onlyList: boolean) {
    if (!onlyList) {
      this.openAddItemDialog(null,null,"Add");
    } else {
      this.router.navigate([`${this.baseRoute}/${data}/new`]);
    }
  }

  openAddItemDialog(editdataNew: any, id: any, title: string): void {
    const dialogRef = this.dilogref.open(RoomPopupComponent, {
      width: '500px',
      data: {
        viewId: this.tabHead,
        title: title,
        editData: editdataNew,
        roomId: id
      },
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.filterBy = { 'keyword': this.searchTerm, 'tabHead': this.activeTab, 'addFilter':this.addOnFilter };
      this.fetchData(this.filterBy);
    });
  }
  
  onEditClickRow(data: any, id: number, state: any, attributes: any, onlyList: boolean) {
    if (!onlyList) {
      this.dynamicTableService.getCollectionEntry(data, id).subscribe(res => {
        this.editData = res.body.data.attributes;
        this.openAddItemDialog(this.editData,id, "Edit");
      })
    } else {  
        this.router.navigate([`${this.baseRoute}/${data}/edit`], { queryParamsHandling: 'merge', queryParams: { id: id } });  
    }
  }

  onViewClickRow(data: any, id: number, state: any, attributes: any) {
    this.router.navigate([`${this.baseRoute}/${data}/view`], { queryParamsHandling: 'merge', queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead } });
  }

  onDeleteConfirmationClick(data: string, id: any): void {

  }

  onMarkAttendance(data: any, id: number, state: any, attributes: any) {
    if(this.collection === 'courseDeliveries'){
      this.courseName=attributes?.courses?.data?.attributes?.courseName;
      this.router.navigate([`${this.baseRoute}/${data}/mark-attendance`], { queryParamsHandling: 'merge', queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead  ,courseName:this.courseName} });
    }else if(this.collection==='eventDeliveries'){
      this.courseName=attributes?.events?.data?.attributes?.eventName;
      this.router.navigate([`${this.baseRoute}/${data}/event-Deliveries-mark-attendance`], { queryParamsHandling: 'merge', queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead ,courseName:this.courseName } });
    }else if(this.collection==='workshopDeliveries'){
      this.courseName=attributes?.workshops?.data?.attributes?.workshopName;
      this.router.navigate([`${this.baseRoute}/${data}/workshop-deliveries-mark-attendance`], { queryParamsHandling: 'merge', queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead ,courseName:this.courseName } });
    }
  }

  canMarkAttendance(deliveryStartDate: string): boolean {
    if (!deliveryStartDate) {
      return false;
    }
    const currentDate = new Date();
    const dateParts = deliveryStartDate.split('/');
    if (dateParts.length !== 3) {
      console.error("Invalid date format:", deliveryStartDate);
      return false;
    }
    const [day, month, year] = dateParts.map(part => parseInt(part, 10));
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      console.error("Invalid date components:", { day, month, year });
      return false;
    }
    const startDate = new Date(year, month - 1, day); 
    const oneDayAfterStartDate = new Date(startDate);
    oneDayAfterStartDate.setDate(startDate.getDate() + 1);
    if(currentDate >= oneDayAfterStartDate){
      return true;
    }else if(deliveryStartDate==null){
      return false;
    }
  
  }
  
  isAuthorized(operation) {
    var opId;
    var fId;
    if(this.featureMap.hasOwnProperty(this.collection)){
      var feature = this.featureMap[this.collection];
      if(feature.hasOwnProperty("fId"))
      {
        fId = feature["fId"]
      }
      if(feature.hasOwnProperty(operation))
      {
         opId=feature[operation]
      }

    }

      if(fId ==null || opId == null)
      {
        return false;
      }    
      else{
        return this.sessionService.hasResource([fId.toString(), opId.toString()])
      }
  }

  onOverWriteClick(collection, id) {
    this.dynamicTableService.getCollectionEntry(collection, id).subscribe(response => {
      this.allDataById = response.body.data.attributes;  
      this.repostForNewApplications = this.allDataById?.repostForNewApplications;
     
      const dialogRef = this.dilogref.open(OverWritePopupComponent, {
        width: '500px',
        data: {
          collection: collection,
          entryId: id,
          repostForNewApplications: this.repostForNewApplications
        },
        disableClose: true
      });
 
      dialogRef.afterClosed().subscribe(result => {
       
      });
 
    }, (errors) => {
      this.snackBar.open(errors.error.body.error.message, 'Error', { duration: 3000 });
    });
  }

   formatCollectionName(data: string): string {
    let formattedName = data.endsWith('s') ? data.slice(0, -1) : data;
    formattedName = formattedName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase(); 
    if (formattedName.endsWith('deliverie')) {
      formattedName = formattedName.replace('deliverie', 'delivery');
    }
    return formattedName;
  }

  onAssignCourse(data: any, id: number, state: any, attributes: any) {
    this.dynamicTableService.getCourseDetails(id).subscribe(resp=>{
      if (this.collection === 'courseDeliveries') {
        this.courseName = attributes?.courses?.data?.attributes?.courseName;
        this.router.navigate([`${this.baseRoute}/${data}/assign-course`], { queryParamsHandling: 'merge', queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead, courseName: this.courseName,courseType :resp.courseTypeId} });
      }
    },
    (errors) => {
      this.snackBarService.error(`${errors.error.applicationMessage}`);
    })  
  }

  onAssignWorkshop(data: any, id: number, state: any, attributes: any) {
    this.dynamicTableService.getWorkshopDetails(id).subscribe(resp=>{
      if (this.collection === 'workshopDeliveries') {
        this.workshopName = attributes?.workshops?.data?.attributes?.workshopName;
        this.router.navigate([`${this.baseRoute}/${data}/assign-workshop`], { queryParamsHandling: 'merge', queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead, workshopName: this.workshopName,courseType :resp.courseTypeId} });
      }
    },
    (errors) => {
      this.snackBarService.error(`${errors.error.applicationMessage}`);
    })  
  }

  canAssignCourse(deliveryStartDate: string)
  {
    if (!deliveryStartDate) {
      return false;
    }
    const currentDate = new Date();
    const dateParts = deliveryStartDate.split('/');
    if (dateParts.length !== 3) {
      return false;
    }
    const [day, month, year] = dateParts.map(part => parseInt(part, 10));
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }
    const startDate = new Date(year, month - 1, day); 
    const oneDayAfterStartDate = new Date(startDate);
    oneDayAfterStartDate.setDate(startDate.getDate() + 1);
    if(currentDate < oneDayAfterStartDate){
      return true;
    }else if(deliveryStartDate==null){
      return false;
    }
  }

  isAuthorizedCheck(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }


  onManualEventAssign(data: any, id: number, state: any, attributes: any) {
    this.dynamicTableService.validateEventDetails(id).subscribe(resp => {
      if (this.collection === 'eventDeliveries') {
        this.eventName = attributes?.events?.data?.attributes?.eventName;
        this.router.navigate([`${this.baseRoute}/${data}/assign-event`], {
          queryParamsHandling: 'merge',
          queryParams: { id: id, name: attributes.courseProvider, hideEdit: this.tabHead, eventName: this.eventName }
        });
      }
    },
      (errors) => {
        this.snackBarService.error(`${errors.error.applicationMessage}`);
      })
  }
}
