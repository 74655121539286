<mat-card id="edit_keyword">
    <mat-dialog-content>
        <h3 matDialogTitle class="title" tabindex="0">Assign service leaver</h3>
        <p class="message">Do you want to assign for free?</p>
        <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button class="radio-button" value=true  [disabled]="data.courseType==1">Yes</mat-radio-button>
            <mat-radio-button class="radio-button" value=false>No</mat-radio-button>
        </mat-radio-group>
        <mat-dialog-actions>
            <button type="button" mat-stroked-button (click)="onCancel()" aria-label="close">Cancel</button>
            <button type="button" mat-stroked-button class="primary-color" (click)="onConfirm()"
                aria-label="Delete">OK</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</mat-card>