import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicFormService } from '../dynamic-form/dynamic-form.service';
import { BaseUrl } from '../../../framework/constants/url-constants';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';
import { CaptrLearnersService } from '../../captr-learners/captr-learners.services';
import { url } from 'inspector';
import { isObject } from 'util';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';

@Component({
  selector: 'app-dynamic-form-view',
  templateUrl: './dynamic-form-view.component.html',
  styleUrls: ['./dynamic-form-view.component.scss']
})
export class DynamicFormViewComponent {

  @Input() identifier!: string;
  @Input() routeIntent!: string;
  @Input() schemaUrl!: string;
  @Input() apiUrl!: string;
  @Input() titleInformation!: any[];
  @Input() collection!: string;
  @Input() addRelationFields!: {};

  form = new FormGroup({});
  model = {};
  fields!: FormlyFieldConfig[];
  formData: any[] = [];
  showValuesAsList: string[] = ['ref_course_quals'];   // Applies only for one-to-many relations
  exceptionTypes: string[] = ['title', 'relation', 'single', 'repeat', 'file'];   // Common rendering not used for these types
  tempList: any;
  skillList: any;
  skillsId: any;
  recordOwnerArray: string[] = ['/employers', '/dosServiceProviders']

  constructor(
    private http: HttpClient,
    private dynamicFormService: DynamicFormService,
    private readonly dynamicTableService: DynamicTableService,
    private readonly captrLearnersService: CaptrLearnersService,
    private dialog: MatDialog,
    private readonly snackBarService: SnackBarService
  ) { }

  ngOnInit() {
    if (this.recordOwnerArray.includes(this.collection)) {
      this.dynamicTableService
        .getExternalList('fullname,asc', 0, 200, { keyword: "" }, 'user-detail/getAllKWUsersByClientIdPaginated', 'user').subscribe(data => {
          this.tempList = data.content
        })
    }
    this.http.get(`${BaseUrl.CMS_PROXY_API}/open/${this.schemaUrl}/getFormSchema`, { observe: 'response' }).toPromise()
      .then((response: HttpResponse<any>) => {
        this.fields = response?.body?.body as FormlyFieldConfig[];
        if (this.titleInformation) {
          this.titleInformation.forEach(element => {
            for (const [fieldKey, title] of Object.entries(element)) {
              let found = false;

              this.fields.forEach((fieldGroup, index) => {
                if (found) return; // Break out of the loop if already found
                fieldGroup.fieldGroup.forEach(field => {
                  const key = field.key.toString();

                  if (key === fieldKey) {
                    // Create a new fieldGroup with the title as label
                    const newFieldGroup = {
                      fieldGroup: [
                        {
                          key: title.toString().replace(/\s/g, ""), // Provide a unique key for the new field
                          type: "title",
                          templateOptions: {
                            label: title.toString(), // Explicitly cast title to string
                            placeholder: "",
                            description: "",
                            options: [],
                            minLength: 3,
                            maxLength: 100,
                            disabled: false
                          },
                        }
                      ],
                    };
                    this.fields.splice(index, 0, newFieldGroup);
                    found = true;
                  }
                });
              });
            }
          });
        }

        if (this.identifier) {
          this.dynamicFormService.getCollectionEntry(this.identifier, this.collection).subscribe(response => {
            this.model = response.body.data.attributes
            this.fields.forEach(fieldGroup => {
              fieldGroup.fieldGroup.forEach(field => {
                const label = field.templateOptions.label;
                const key = field.key.toString();
                if (key in this.model) {
                  switch (field.type) {
                    case 'relation':
                      this.formData.push({
                        key: key,
                        value: this.model[key],
                        label: label,
                        type: field.type,
                        mainField: field.templateOptions.mainField,
                        relation: field.templateOptions.relation
                      });
                      this.checkAddRelationFields(key);
                      break;

                    case 'single':
                      let componentFieldValue = {};
                      field.fieldGroup.forEach(element => {
                        element.fieldGroup.forEach(component => {
                          let componentFieldKey: any = component.key;
                          if (this.model[key]?.hasOwnProperty(componentFieldKey)) {
                            componentFieldValue[component.templateOptions.label] = this.model[key][componentFieldKey];
                          } else {
                            componentFieldValue[component.templateOptions.label] = 'N/A';
                          }
                        });
                      });
                      this.formData.push({
                        key: key,
                        value: componentFieldValue,
                        label: label,
                        type: field.type,
                      });
                      break;

                    case 'repeat':
                      const repeatComponentFieldValue = this.buildRepeatComponentFieldValue(key, field);
                      this.formData.push({
                        key: key,
                        value: repeatComponentFieldValue,
                        label: label,
                        type: field.type,
                      });
                      break;

                    case 'file':
                      const allowedTypes: string[] = field.templateOptions?.allowedTypes;
                      const loadFile: boolean = allowedTypes && allowedTypes.length == 1 && allowedTypes[0] === 'images';
                      this.formData.push({
                        key: key,
                        value: this.model[key],
                        label: label,
                        type: field.type,
                        loadFile: loadFile
                      });
                      break;

                    case 'postcode':
                      let componentFieldValue1 = {};
                      field.fieldGroup.forEach(element => {
                        element.fieldGroup.forEach(component => {
                          let componentFieldKey: any = component.key;
                          if (this.model[key]?.hasOwnProperty(componentFieldKey)) {
                            componentFieldValue1[component.templateOptions.label] = this.model[key][componentFieldKey];
                          }
                        });
                      });
                      this.formData.push({
                        key: key,
                        value: componentFieldValue1,
                        label: label,
                        type: field.type,
                      });
                      break;

                    default:
                      this.formData.push({
                        key: key,
                        value: this.model[key],
                        label: label,
                        type: field.type,
                      });
                  }
                }
                if (field.type === 'title') {
                  this.formData.push({
                    key: key,
                    label: label,
                    type: field.type,
                  });
                }
              });
            });
          });
        }
      });
    this.fetchUserData();
  }

  fetchUserData() {
    this.dynamicTableService
      .getExternalList('fullname,asc', 0, 200, { keyword: "" }, 'user-detail/getAllKWUsersByClientIdPaginated', 'user').subscribe(data => {
        this.tempList = data.content
      })
  }

  checkAddRelationFields(key: string) {
    if (this.addRelationFields && this.addRelationFields[key] !== undefined) {
      const data = this.model[key].data;
      if (data !== null) {
        const addFields: [{}] = this.addRelationFields[key];
        addFields.forEach(field => {
          const attributeName = field['attribute'];
          this.formData.push({
            key: attributeName,
            value: data.attributes[attributeName],
            label: field['label'],
            type: field['type'],
          });
        })
      }
    }
  }

  buildRepeatComponentFieldValue(modelKey: string, schemaField: any) {
    let componentFieldValue = [];
    this.model[modelKey].forEach(subModel => {
      let componentFieldArray: any[] = [];
      schemaField.fieldArray['fieldGroup'].forEach(fieldArrayObj => {
        fieldArrayObj.fieldGroup.forEach(fieldGroupObj => {
          let componentField: any = {};
          const componentFieldKey: any = fieldGroupObj.key;
          if (subModel.hasOwnProperty(componentFieldKey)) {
            componentField.key = componentFieldKey;
            if (fieldGroupObj.fieldGroup) {
              let newcomponentFieldArray: any[] = [];
              fieldGroupObj.fieldGroup.forEach(newFieldGroup => {
                const newComponentFieldKey: any = newFieldGroup.fieldGroup[0].key;

                if (subModel[componentFieldKey]?.hasOwnProperty(newComponentFieldKey)) {
                  let subField: any = {};
                  subField.key = newComponentFieldKey;
                  subField.value = subModel[componentFieldKey][newComponentFieldKey];
                  subField.type = newFieldGroup.fieldGroup[0].type;
                  subField.label = newFieldGroup.fieldGroup[0].templateOptions.label;
                  newcomponentFieldArray.push(subField);
                }
              });
              componentField.value = newcomponentFieldArray;
            } else {
              componentField.value = subModel[componentFieldKey];
            }

            componentField.type = fieldGroupObj.type;
            componentField.label = fieldGroupObj.templateOptions.label;
            componentFieldArray.push(componentField);
          }
        });
      });
      componentFieldValue.push(componentFieldArray);
    });
    return componentFieldValue;
  }



  resolveValue(item: any): string {
    if (!item || item?.value === null || item.value?.data === null) {
      return 'N/A';
    }
    if (item.key === 'providerWebsite' && typeof item.value === 'string') {
      return `<a href="${item.value}" target="_blank" rel="noopener noreferrer">${item.value}</a>`;
    }
    if (item.value?.data) {
      switch (item.type) {
        case 'relation':
          return item.value.data.attributes[item.mainField];
        case 'file':
          return item.value.data.attributes.name;
      }
    } else if (item?.type === 'datepicker') {
      if (item?.value instanceof Date || typeof item?.value === 'string') {
        return formatDate(item.value, 'dd/MM/yyyy', 'en-gb');
      }
    } else if (typeof item.value === 'string' && item.value.trim().length === 0) {
      return 'N/A';
    } else if (item.type === 'radio') {
      return item.value ? 'Yes' : 'No';
    } else if (item.type === 'time') {
      const [hours, minutes] = item.value?.split(':');
      return `${hours}:${minutes}`;
    }
    if ((item.key === 'serviceOwnerId' || item.key === 'recordOwnerId') && typeof item.value === 'string') {
      const userId = parseInt(item.value);
      if (userId != null && !isNaN(userId)) {
        const userFullName = this.getFullNameById(userId, this.tempList);
        item.value = userFullName;
      }

    }
    if (item.key === 'lastContact') {
      return formatDate(item.value, 'dd/MM/yyyy', 'en-gb');
    }
    if (item.key === 'skills') {
      if (item.value.endsWith(',')) {
        item.value = item.value.slice(0, -1);
      }
      const stringArray = item.value.split(',');

      this.skillsId = stringArray.map(Number);
      item.value = this.getMatchingSkills();
    }
    if ((item.key === 'courseCost' || item.key === 'courseMaterials' || item.key === 'courseFee' || item.key === 'examFee' || item.key === 'salaryRange1' || item.key === 'salaryRange2') && typeof item.value === 'number') {
      if (Number.isInteger(item.value)) {
        item.value = item.value.toFixed(2);
      }
    }
    return item.value;
  }

  getMatchingSkills(): string[] {
    return this.skillList
      .filter(skill => this.skillsId?.includes(skill?.id))
      .map(skill => skill.text);
  }

  getSkillFromCV() {
    this.captrLearnersService.getPersonalStatementInfo().subscribe(res => {
      this.skillList = res[0].answers;
    });
  }

  getFullNameById(id: number, tempList: any[]): string | undefined {
    const matchedUser = tempList ? tempList.find(user => user?.id === id) : null;
    return matchedUser ? matchedUser?.fullName : "N/A";
  }

  showHideElement(item) {
    return true;
  }

  truncateText(text: string): string {
    if (text.length > 80) {
      return text.substring(0, 80) + '...';
    } else {
      return text;
    }
  }

  notExceptionType(fieldType: string): boolean {
    return !this.exceptionTypes.includes(fieldType);
  }

  downloadFile(item: any): void {
    const isMalicious = item.value.data.attributes.isMalicious;
    const url = item.value.data.attributes.url;
    const fileName = item.value.data.attributes.name;
    if (isMalicious == null) {
      this.snackBarService.error(`File scanning in progress`);
    }
    else if(isMalicious){
      this.snackBarService.error(`The file is malicious`);
    }
    else {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const a = document.createElement('a');
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;

          // Programmatically trigger the download
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          // Revoke the object URL to free up resources
          URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Download error:', error));
    }
  }

  isObject(value: any): boolean {
    return value && typeof value === 'object' && !Array.isArray(value);
  }

  transformKeyName(key: string): string {
    if (key.includes('/')) {
      return key;
    }
    if (key != 'Website URL') {
      key = key.replace(/(\d)/g, ' $1');
      key = key.replace(/([a-z])([A-Z])/g, '$1 $2');
      key = key.replace(/([a-z])([A-Z])/g, '$1 $2');
      key = key.replace(/\b\w/g, function (char, index) {
        return index === 0 ? char.toUpperCase() : char.toLowerCase();
      });
    }
    return key;
  }



}
