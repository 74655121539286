import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Utility } from 'src/app/framework/utils/utility';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { DynamicFormService } from 'src/app/features/dynamic-forms/dynamic-form/dynamic-form.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryClashComponent } from '../../delivery-clash/delivery-clash.component';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { SharedServiceService } from 'src/app/framework/shared/shared.service';

@Component({

  selector: 'app-add-edit-course-delivery',
  templateUrl: './add-edit-course-delivery.component.html',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AddEditCourseDeliveryComponent {
  formSubmitted: boolean = false;
  foundField: any;
  uploadPromises = [];
  imageId: any;
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "courses",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Course name is required.",
            }
          },
          "templateOptions": {
            "label": "Course name",
            "placeholder": "",
            "description": "Please select an option from the list",
            "options": [],
            "api": "courses",
            "relation": "one",
            "mainField": "courseName",
            "required": true,
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
          {
              "key": "provider",
              "type": "relation",
              "className": "col-md-6",
              "validation": {
                "messages": {
                  "required": "Provider is required.",
                }
              },
              "templateOptions": {
                  "label": "Provider",
                  "placeholder": "",
                  "description": "Please select an option from the list",
                  "options": [],
                  "api": "providers",
                  "relation": "one",
                  "mainField": "providerName",
                  "required": true,
              },
              "fieldArray": null,
              "fieldGroupClassName": null,
              "fieldGroup": null
          }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "courseDeliveryId",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Course delivery ID",
            "placeholder": "",
            "description": "",
            "options": []
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_delivery_types",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Delivery type is required.",
            }
          },
          "templateOptions": {
            "label": "Delivery type",
            "placeholder": "",
            "description": "Please select an option from the list",
            "options": [],
            "api": "ref-delivery-types",
            "relation": "one",
            "mainField": "name",
            "required": true,
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "location",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Location",
            "placeholder": "",
            "description": "Please select an option from the list",
            "options": [],
            "api": "courseLocations",
            "relation": "one",
            "mainField": "locationName"
          },
          "expressions": { "hide": "model.ref_delivery_types == 2" },
          "expressionProperties": {
            "templateOptions.required": "model.ref_delivery_types != 2"
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "room_location",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Room",
            "placeholder": "",
            "description": "Please select an option from the list",
            "options": [],
            "api": "roomLocations",
            "relation": "one",
            "mainField": "roomName",
            "filterByRelation":"location"
          },
          "expressions": { "hide": "!model.location" },
          "expressionProperties": {
            "templateOptions.required": "model.location"
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "deliveryDateTime",
          "type": "repeat",
          "className": "col-md-12",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Delivery date and time",
            "placeholder": "",
            "description": "At least 1 Delivery date and time is required.",
            "options": [],
            "minQuantity": 1,
            "required": true
          },
          "fieldArray": {
            "fieldGroupClassName": "display-flex-column",
            "fieldGroup": [
              {
                "fieldGroupClassName": "display-flex delivery-date-time-alignment",
                "fieldGroup": [
                  {
                    "key": "deliveryDate",
                    "type": "datepicker",
                    "className": "col-md-4",
                    "validation": {
                      "messages": {
                        "required": "Delivery date is required.",
                        "minDate": "Delivery date must be tomorrow or later",
                        "invalidDate": "Delivery date is required."
                      }
                    },
                    "validators": {
                      "validation": ['minDate','invalidDate'],
                    },
                    "templateOptions": {
                      "label": "Delivery date",
                      "placeholder": "",
                      "description": "",
                      "required": true,
                      "options": [],
                      "datepickerOptions": {
                        "min": new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                      }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  },
                  {
                    "key": "deliveryStartTime",
                    "type": "time",
                    "className": "col-md-4",
                    "validation": {
                      "messages": {
                        "required": "Start time is required."
                      }
                    },
                    "templateOptions": {
                      "label": "Start time",
                      "placeholder": "",
                      "description": "",
                      "required": true,
                      "options": []
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  },
                  {
                    "key": "deliveryEndTime",
                    "type": "time",
                    "className": "col-md-4",
                    "validation": {
                      "messages": {
                        "required": "End time is required."
                      }
                    },
                    "templateOptions": {
                      "label": "End time",
                      "placeholder": "",
                      "description": "",
                      "required": true,
                      "options": [],
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  }
                ]
              }
            ]
          },
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_event_ranks",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Rank tag",
            "placeholder": "Rank",
            "description": "Please select an option from the list",
            "options": [],
            "api": "ref-event-ranks",
            "relation": "many",
            "mainField": "name",
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "deliveryCapacity",
          "type": "number",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Delivery capacity is required.",
              "pattern": "This field format is invalid.",
              "min": "Minimum delivery capacity must be 1.",
              "max": "Maximum delivery capacity must be 999.",
            }
          },
          "templateOptions": {
            "label": "Delivery capacity",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": [],
            "pattern": "^[0-9]*$",
            "min": 1,
            "max": 999
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
          {
              "key": "ref_course_delivery_status",
              "type": "relation",
              "className": "col-md-6",
              "validation": {
                  "messages": {}
              },
              "templateOptions": {
                  "label": "Course delivery status",
                  "placeholder": "",
                  "description": "",
                  "options": [],
                  "api": "ref-course-delivery-statuses",
                  "relation": "one",
                  "mainField": "name"
              },
              "fieldArray": null,
              "fieldGroupClassName": null,
              "fieldGroup": null
          }
      ]
  },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_can_reason",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Cancellation reason",
            "placeholder": "",
            "description": "",
            "options": [],
            "api": "ref-can-reasons",
            "relation": "one",
            "mainField": "name"
          },
          "expressions": { "hide": "model.ref_course_delivery_status != 2" },

          "expressionProperties": {
            "templateOptions.required": "model.ref_course_delivery_status === 2"
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_shared_programmes",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {}
          },
          "templateOptions": {
            "label": "Programmes",
            "placeholder": "",
            "description": "Please select an option from the list",
            "options": [],
            "api": "ref-shared-programmes",
            "relation": "many",
            "mainField": "name",
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    }
  ]

  rowId: any;
  routeIntent: string;
  state: any;
  myGroup: FormGroup;

  identifier: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  routerPath = '/courses-events/courseDeliveries/view/details';
  collection = "/courseDeliveries";
  queryParams: any;
  responseObj: boolean=false;
  hideEdit: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly router: Router,
    private snackBar: MatSnackBar,
    private dynamicFormService: DynamicFormService,
    private readonly dilogref: MatDialog,
    private readonly snackBarService: SnackBarService,
    private readonly appConfirmService: AppConfirmService,
    private readonly sharedServiceService :SharedServiceService
  ) {
    this.scrollToTop();
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.id) {
        this.rowId = params.id;
        this.state = params.state;
        this.routeIntent = "edit";
      } else {
        this.routeIntent = "new"
      }
    });
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.queryParams = { id: this.rowId };
    this.schemaUrl = 'api::course-delivery.course-delivery';
    this.apiUrl = '/course-deliveries';
    this.titleInformation = [{
      'deliveryDateTime': 'Delivery days and times'
    }
    ];
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;

      if (params.id) {
        this.identifier = params.id;
        this.routeIntent = "edit";
      }

    });
  }

  ngOnInit(): void {
    this.addFieldHideExpression();
    if (this.identifier) {
      this.dynamicFormService.getCollectionEntry(this.identifier, this.collection).subscribe(response => {
        this.model = response.body.data.attributes;

      }, (errors) => {
        this.snackBar.open(errors.error.body.error.message, 'Error', { duration: 3000 });
      })
    }
    this.form.valueChanges.subscribe(() => {
      this.sharedServiceService.setFormDirty(this.form.dirty);
      this.form.markAsTouched();
    });
  }

  ngOnDestroy() {
    this.sharedServiceService.setFormDirty(false);
    this.inPageNavService.setNavItems(null);
  }

  exitNavigation() {
    if (this.queryParams && this.queryParams.id) {
        this.router.navigate(['/courses-events/courseDeliveries/view'], {
            queryParams: {
                id: this.queryParams.id,
                hideEdit: "false" 
            }
        });
    } else {
        this.router.navigate(['/courses-events/courseDeliveries']);
    }
  }
  addFieldHideExpression() {
    this.fields.forEach(fieldGroup => {
      fieldGroup.fieldGroup.forEach(field => {
        if (field.key == 'courseDeliveryId') {
          if (this.routeIntent == 'edit') {
            field.hideExpression = false;
            field.templateOptions.disabled = true;

          } else {
            field.hideExpression = true;
            field.templateOptions.disabled = false;
          }
        }
        // if (field.key == 'ref_course_delivery_status' || field.key == 'ref_can_reason') {
        //   if (this.routeIntent == 'edit') {
        //     field.hideExpression = false;
        //     field.templateOptions.disabled = false;

        //   } else {
        //     field.hideExpression = true;
        //     field.templateOptions.disabled = false;
        //   }
        // }
      });
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async onSubmit() {
    this.responseObj=true;
    // Call your processForm() method here
    this.processForm();
  }



  async processForm() {
    const timeRegex = /(?:[01]\d|2[0-3]):[0-5]\d/;

    const processObject = (obj, index = null) => {
      if (obj !== null) {
        Object.entries(obj).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item, idx) => {
              processObject(item, idx);
            });
          } else if (!(value instanceof Date) && typeof value === 'object') {
            processObject(value);
          } else {
            checkAndUpdateValue(value, key, index);
          }
        });
      }
    };

    // Define the function to check and update values
    const checkAndUpdateValue = (value, key, arrayIndex) => {
      const hasTimeFormat = typeof value === 'string' && timeRegex.test(value);
      if (hasTimeFormat) {
        if (key === 'deliveryStartTime' || key === 'deliveryEndTime') {
          if (this.model['deliveryDateTime'] && Array.isArray(this.model['deliveryDateTime'])) {
            this.model['deliveryDateTime'][arrayIndex][key] = this.convertTimeFormat(value);
          }
        } else {
          this.model[key] = this.convertTimeFormat(value);
        }
      }
      if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) && value.length > 2) {
        const parsedDate = Date.parse(value);
        if (!isNaN(parsedDate) && parsedDate > 0) {
          const dateObj = new Date(parsedDate);
          const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
          this.model[key] = formattedDate;
        }
      }
      if (key === 'deliveryDate') {
        const dateField = this.model['deliveryDateTime'][arrayIndex][key];
        const formattedDateNew = Utility.transformDateToString(dateField);
        this.model['deliveryDateTime'][arrayIndex][key] = formattedDateNew;
      }
    };

    processObject(this.model);

    if (this.model['deliveryDateTime'] && Array.isArray(this.model['deliveryDateTime'])) {
      for (let index = 0; index < this.model['deliveryDateTime'].length; index++) {
        const data = this.model['deliveryDateTime'][index];
        if (data.deliveryEndTime <= data.deliveryStartTime) {
          this.reConvertTime();
          this.snackBarService.error('End time should be greater than start time for Delivery date and time ' + (index + 1));
          return; // Return if the condition fails for any entry
        }
      }
    }

    // If all entries pass the checks, submit the form
    this.submitForm();
  }


  async submitForm() {
    const payload = this.formattedPayload(this.model)
    if(this.model['ref_delivery_types'] == 2){
      payload.location = [];
      payload.room_location = [];
    }
    if (this.identifier) {
      if(this.model['ref_course_delivery_status'] != 2){
        payload.ref_can_reason = [];
      }
      this.dynamicFormService.updateCollectionEntry({ data: payload }, this.identifier, this.collection).subscribe(response => {
        this.hideEdit = response?.responseObject?.body?.data?.attributes?.cancelled;
        this.snackBarService.success(response?.message.applicationMessage);
        this.router.navigate([this.routerPath],{queryParams: {
          id: this.queryParams.id,
          hideEdit: this.hideEdit 
      }});
      }, error => {
        if (this.roomConflicts(error)) {
          this.openDialog(error, payload, this.identifier, this.collection, this.routerPath)
        } else {
          this.reConvertTime();
          if(error.error.status==500){
            this.snackBarService.error(error.error.error);
          }
          else{
            this.snackBarService.error(error.error.body.error.message);
          }
        }
      })
    } else {
      this.dynamicFormService.saveCollectionEntry({ data: payload }, this.collection).subscribe(response => {
        this.hideEdit = response?.responseObject?.body?.data?.attributes?.cancelled;
        this.snackBarService.success(response?.message.applicationMessage);
        this.router.navigate([this.routerPath],{queryParams: {
          id: response.responseObject.body.data.id,
          hideEdit: this.hideEdit 
      }});
      }, error => {
        if (this.roomConflicts(error)) {
          this.openDialog(error, payload, this.identifier, this.collection, this.routerPath)
        } else {
          this.reConvertTime();
          if(error.error.status==500){
            this.snackBarService.error(error.error.error);
          }
          else{
            this.snackBarService.error(error.error.body.error.message);
          }
        }
      })
    }
  }

  reConvertTime(){
    this.model['deliveryDateTime'].forEach((element,index)=>{
      this.model['deliveryDateTime'][index]['deliveryStartTime'] = convertTime(element['deliveryStartTime'])
      this.model['deliveryDateTime'][index]['deliveryEndTime'] = convertTime(element['deliveryEndTime'])
    })
     function convertTime(time: string): string {
      const hours = time.slice(0, 2);
      const minutes = time.slice(3, 5);
      return `${hours}:${minutes}`;
    }
  }

  openDialog(error, payload, identifier, collection, routerPath): void {
    const dialogRef = this.dilogref.open(DeliveryClashComponent, {
      width: '500px', maxHeight: '500px',
      data: { "error": error, "payload": payload, "id": identifier, "collection": collection, "routerPath": routerPath }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  formattedPayload(payload) {
    Object.keys(payload).forEach(key => {
      const controlValue = payload[key];
      if (controlValue === '') {
        payload[key] = null;
      }
      if (Array.isArray(controlValue?.data) && controlValue?.data?.length === 0) {
        payload[key] = [];
      }
    });
    let startDate;
    let endDate;
    for (let index = 0; index < this.model['deliveryDateTime'].length; index++) {
      if(index==0){
      startDate=this.model['deliveryDateTime'][index]['deliveryDate'];
      endDate=this.model['deliveryDateTime'][index]['deliveryDate'];
    }
      if(startDate>this.model['deliveryDateTime'][index]['deliveryDate']){
        startDate=this.model['deliveryDateTime'][index]['deliveryDate']
      }
      if(endDate<this.model['deliveryDateTime'][index]['deliveryDate']){
        endDate=this.model['deliveryDateTime'][index]['deliveryDate']
      }
    }
    payload['deliveryEndDate']=endDate;
    payload['deliveryStartDate']=startDate;
    payload['overrideConflict'] = false;
    return payload;
  }

  convertTimeFormat(inputTime: string): string {
    const [hoursStr, minutesStr] = inputTime.split(':');

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    const seconds = 0;
    const milliseconds = 0;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const formattedMilliseconds = milliseconds.toString().padStart(3, '0');

    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;

    return formattedTime;
  }

  roomConflicts(error: any) : boolean {
    if (error?.error?.body?.error?.details?.courseConflicts || error?.error?.body?.error?.details?.eventConflicts || error?.error?.body?.error?.details?.workshopConflicts) {
      return true;
    }
    return false;
  }

  canDeactivate() {
    if (this.form.dirty && this.form.touched && !this.responseObj) {
      return this.appConfirmService.confirm(
        {
          title: `Progress not saved`,
          message: 'If you navigate away from this page your progress will not be saved'
        });
    }
    return true;
  }
}

