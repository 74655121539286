<mat-tab-group (selectedIndexChange)="onTabChanged($event)" [selectedIndex]="activeIndex" mat-stretch-tabs="false"
    mat-align-tabs="start" dynamicHeight fitInkBarToContent>
    <mat-tab label="Pre-release">
        <ng-template matTabContent>
            <app-dynamic-table [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields"
                [buttonLable]="buttonLable" [baseRoute]="baseRoute" [tabHead]="archiveTab2">
            </app-dynamic-table>
        </ng-template>
    </mat-tab>
    <mat-tab label="Active">
        <ng-template matTabContent>
            <app-dynamic-table [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields"
                [buttonLable]="buttonLable" [baseRoute]="baseRoute" [tabHead]="archiveTab">
            </app-dynamic-table>
        </ng-template>
    </mat-tab>
    <mat-tab label="Archived">
        <ng-template matTabContent>
            <app-dynamic-table [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields"
                [buttonLable]="buttonLable" [baseRoute]="baseRoute" [tabHead]="archiveTab1">
            </app-dynamic-table>
        </ng-template>
    </mat-tab>
</mat-tab-group>